<template>
    <div>
        <b-row>
            <b-col md="5">
                <img src="../assets/signup-banner.png"  class="img-fluid" alt="">
            </b-col>
            <b-col md="6">
                <form @submit.prevent="createAccount">
                    <b-row>
                        <b-col class="form-group" md="6">
                            <label>Full Name <span class="text-danger">*</span></label>
                            <input type="text" name="fullname" id="fullname" class="form-control" placeholder="Full Name" v-model="fullname">
                            <small v-if="v$.fullname.$error" class="is-invalid">{{ v$.fullname.$errors[0].$message }}</small>
                        </b-col>
                        <b-col class="form-group" md="6">
                            <label>Mobile Number <span class="text-danger">*</span></label>
                            <input type="text" name="mobile" id="mobile" class="form-control" placeholder="Mobile Number" v-model="mobile">
                            <small v-if="v$.mobile.$error" class="is-invalid">{{ v$.mobile.$errors[0].$message }}</small>
                        </b-col>                        
                    </b-row>
                    <b-row>
                        <b-col class="form-group" md="6">
                            <label>Email Address <span class="text-danger">*</span></label>
                            <input type="text" name="email" id="email" class="form-control" placeholder="Email Address" v-model="email">
                            <small v-if="v$.email.$error" class="is-invalid">{{ v$.email.$errors[0].$message }}</small>
                        </b-col>
                        <b-col class="form-group" md="6">
                            <label>Business Name <span class="text-danger">*</span></label>
                            <input type="text" name="business" id="business" class="form-control" placeholder="Email Address" v-model="business">
                            <small v-if="v$.business.$error" class="is-invalid">{{ v$.business.$errors[0].$message }}</small>
                        </b-col>
                    </b-row>
                    <div class="form-group">
                        <label>GSTIN <span class="text-danger">*</span></label>
                        <input type="text" name="gstin" id="gstin" class="form-control" placeholder="Email Address" v-model="gstin">
                        <small v-if="v$.gstin.$error" class="is-invalid">{{ v$.gstin.$errors[0].$message }}</small>
                    </div>
                    <b-row>
                        <b-col class="form-group" md="6">
                            <label>Password <span class="text-danger">*</span></label>
                            <input type="password" name="password" id="password" class="form-control" placeholder="Password" v-model="password">
                            <small v-if="v$.password.$error" class="is-invalid">{{ v$.password.$errors[0].$message }}</small>
                        </b-col>
                        <b-col class="form-group" md="6">
                            <label>Confirm Password <span class="text-danger">*</span></label>
                            <input type="password" name="con_password" id="con_password" class="form-control" placeholder="Confirm Password" v-model="conpassword">
                            <small v-if="v$.conpassword.$error" class="is-invalid">{{ v$.conpassword.$errors[0].$message }}</small>
                        </b-col>
                    </b-row>
                    <button type="submit" class="btn btn-primary">CREATE ACCOUNT</button>
                </form>
            </b-col>            
        </b-row>    
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, numeric, minLength, maxLength, email, sameAs, alphaNum } from '@vuelidate/validators'

export default{
    name: 'Signup',
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            fullname: '',
            email: '',
            mobile: '',
            business: '',
            gstin: '',
            password: '',
            conpassword: ''
        }
    },
    validations(){
        return {
            fullname: { required, minLength: minLength(4)},
            mobile: { required, numeric, minLength: minLength(10), maxLength: maxLength(10) },
            email: { required, email },
            business: { required, minLength: minLength(3)},
            gstin: { required, minLength: minLength(15), maxLength: maxLength(15), alphaNum},
            password: { required, minLength: minLength(8) },          
            conpassword: { sameAs: sameAs(this.password) }
        }
    },
    methods: {
        async createAccount(){            
            await this.v$.$validate()
            console.log(this.v$)
        }
    }
}
</script>