<template>
    <div id="calc">
        <form id="calculator-frm" @submit.prevent="calculatePrice">
            <b-row>                
                <b-col class="form-group" fluid="md">
                    <small>Production Cost (Incl. Taxes)
                        <span class="float-right">
                            <a href="#" v-b-tooltip.hover.top="'This info is used to calculate the BURN RATE on logistic cost.'">
                                <svg width="15px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"/></svg>
                            </a>
                        </span>
                    </small>
                    <input type="text" name="mfg_price" id="mfg_price" class="form-control" placeholder="Mfg. Price" v-model="mfg_price" value="0">
                    <small v-if="v$.mfg_price.$error" class="is-invalid">{{ v$.mfg_price.$errors[0].$message }}</small>
                </b-col>
                <b-col class="form-group" fluid="md">
                    <small>Product Weight (In Kgs) <span class="text-danger">*</span>
                        <span class="float-right">
                            <a href="#" v-b-tooltip.hover.top="'Product weight + Average weight of packing.'">
                                <svg width="15px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"/></svg>
                            </a>
                        </span>
                    </small>
                    <input type="text" name="weight" id="weight" class="form-control" placeholder="Product Weight" v-model="weight">
                    <small v-if="v$.weight.$error" class="is-invalid">{{ v$.weight.$errors[0].$message }}</small>
                </b-col>
                <b-col class="form-group" fluid="md">
                    <small>Product Length (In centimeters) <span class="text-danger">*</span>
                        <span class="float-right">
                            <a href="#" v-b-tooltip.hover.top="'Product length + Average length of packing.'">
                                <svg width="15px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"/></svg>
                            </a>
                        </span>
                    </small>
                    <input type="text" name="length" id="length" class="form-control" placeholder="Product Length" v-model="length">                    
                    <small v-if="v$.length.$error" class="is-invalid">{{ v$.length.$errors[0].$message }}</small>
                </b-col>
                <b-col class="form-group" fluid="md">
                    <small>Product Breadth (In centimeters) <span class="text-danger">*</span>
                        <span class="float-right">
                            <a href="#" v-b-tooltip.hover.top="'Product breadth + Average breadth of packing.'">
                                <svg width="15px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"/></svg>
                            </a>
                        </span>
                    </small>
                    <input type="text" name="breadth" id="breadth" class="form-control" placeholder="Product Breadth" v-model="breadth">
                    <small v-if="v$.breadth.$error" class="is-invalid">{{ v$.breadth.$errors[0].$message }}</small>
                </b-col>
                <b-col class="form-group" fluid="md">
                    <small>Product Height (In centimeters) <span class="text-danger">*</span>
                        <span class="float-right">
                            <a href="#" v-b-tooltip.hover.top="'Product height + Average height of packing.'">
                                <svg width="15px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"/></svg>
                            </a>
                        </span>
                    </small>
                    <input type="text" name="heigth" id="height" class="form-control" placeholder="Product Height" v-model="height">
                    <small v-if="v$.height.$error" class="is-invalid">{{ v$.height.$errors[0].$message }}</small>
                </b-col>
            </b-row>            
            <b-row>
                <b-col class="form-group" fluid="md">
                    <small>M.R.P <span class="text-danger">*</span></small>
                    <input type="text" name="mrp" id="mrp" class="form-control" placeholder="M.R.P" v-model="mrp">
                    <small v-if="v$.mrp.$error" class="is-invalid">{{ v$.mrp.$errors[0].$message }}</small>
                </b-col>
                <b-col class="form-group" fluid="md">
                    <small>Offer Price (Incl. Taxes)<span class="text-danger">*</span></small>
                    <input type="text" name="offer_price" id="offer_price" class="form-control" placeholder="Offer Price / Selling Price" v-model="offer_price">
                    <small v-if="v$.offer_price.$error" class="is-invalid">{{ v$.offer_price.$errors[0].$message }}</small>
                </b-col>
                <b-col class="form-group" fluid="md">
                    <small>GST (%) <span class="text-danger">*</span></small>
                    <select name="gst" id="gst" class="form-control" v-model="gst">
                        <option value="0">0 (%)</option>
                        <option value="5">5 (%)</option>
                        <option value="12">12 (%)</option>
                        <option value="18">18 (%)</option>
                        <option value="28">28 (%)</option>                        
                    </select>
                    <small v-if="v$.gst.$error" class="is-invalid">{{ v$.gst.$errors[0].$message }}</small>
                </b-col>
                <b-col class="form-group" fluid="md">
                    <small>Payment Mode <span class="text-danger">*</span></small>
                    <select name="mode" id="mode" class="form-control" v-model="paymentMode">
                        <option value="prepaid">Online Payment</option>                       
                        <option value="cod">Cash On Delivery</option>
                    </select>
                    <small v-if="v$.gst.$error" class="is-invalid">{{ v$.gst.$errors[0].$message }}</small>
                </b-col>
                <b-col class="form-group" fluid="md">
                    <small>Margin on Product (%) <span class="text-danger">*</span>                       
                        <span class="float-right">
                            <a href="#" v-b-tooltip.hover.top="'This is the margin / commission in percentage which you want to give it to our resellers.'">
                                <svg width="15px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"/></svg>
                            </a>
                        </span>
                    </small>                        
                    <input type="text" name="mop" id="mop" class="form-control" placeholder="MOP" v-model="mop">
                    <small v-if="v$.mop.$error" class="is-invalid">{{ v$.mop.$errors[0].$message }}</small>
                </b-col>
            </b-row>            
            <b-row class="mt-3 justify-content-md-center">
                <b-col cols="3">
                    <button type="submit" class="btn btn-lg btn-primary btn-block">Calculate</button>
                </b-col>
            </b-row>
        </form>
        <b-row class="justify-content-md-center mt-3">             
            <center v-if="loader" class="mt-6">
                <img src="../../assets/loader.gif" width="50px" alt="">
            </center>            
        </b-row>
        <b-row class="justify-content-md-center mt-3">             
            <b-col fluid="md" cols="12" id="calulations-section">                                
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import { required, numeric, minValue } from '@vuelidate/validators'

// minLength, maxLength
export default {
    name: 'Calculator',
    setup: () => ({ v$: useVuelidate() }),
    
    data: () => {
        return {            
            bexCoinImg: require('../../assets/coins.png'),
            mrp: 0,
            mop: '',
            offer_price: 0,
            gst: 0,
            // source: '',
            // destination: '',
            mfg_price: 0,
            length: 0,
            breadth: 0,
            height: 0,
            weight: 0,
            paymentMode: 'prepaid',
            loader: false            
        };     
    },
    validations(){
        return {
            mrp: { required, numeric },
            mop: { required, numeric },
            offer_price: { required, numeric, minValue: minValue(1) },
            gst: { required, numeric },
            // source: { required, minLength: minLength(6), maxLength: maxLength(6) },
            // destination: { required, minLength: minLength(6), maxLength: maxLength(6) },
            mfg_price: { numeric },
            length: { required, numeric, minValue: minValue(1) },
            breadth: { required, numeric, minValue: minValue(1) },
            height: { required, numeric, minValue: minValue(1) },
            weight: { required, numeric, minValue: minValue(0.1) },
            paymentMode: { required }            
        }
    },
    methods:{
        async calculatePrice(){            
            const validation = await this.v$.$validate()
            if(validation){
                try{
                    this.loader =  true;
                    let zones = [{"zone":"A","from":"403001","to":"403005","desc":"Within City"},{"zone":"B","from":"560090","to":"590011","desc":"Within State"},{"zone":"C","from":"400102","to":"560078","desc":"Metro to Metro"},{"zone":"D","from":"403001","to":"400102","desc":"Rest Of India, Except NE & J&K"},{"zone":"E","from":"403001","to":"190001","desc":"NE & J&K"}];                    
                    document.getElementById('calulations-section').innerHTML = '';            
                    let zonePricing = [];
                    for(let i = 0; i < zones.length; i++)
                    {
                        const pricing = await this.fetchPickrrPricing(zones[i].from, zones[i].to)
                        const cheapestCourier = await this.getCheapestPrice(pricing, zones[i])
                        zonePricing.push(cheapestCourier);
                    }
                   
                    let calculation = {
                        mrp: parseFloat(this.mrp),
                        mop: parseFloat(this.mop),
                        sp: parseFloat(this.offer_price),
                        gst: parseFloat(this.gst),
                        mfg: parseFloat(this.mfg_price)                        
                    };            
                    calculation.off = calculation.mrp - calculation.sp;
                    calculation.offPercent = (calculation.off / calculation.mrp) * 100;
                    calculation.taxable = calculation.sp - (calculation.sp * calculation.gst) / (100 + calculation.gst);
                    calculation.taxAmount = calculation.sp - calculation.taxable;            
                                
                    calculation.mopAmount = (calculation.taxable * calculation.mop) / 100;
                    let commSettings = await this.fetchSettings('commission_settings');            
                    let coinSettings = await this.fetchSettings('coins_settings');                  
                    commSettings.data.settings.map((item) => {
                        if(Math.round(calculation.mopAmount) >= item.minMOP && Math.round(calculation.mopAmount) <= item.maxMOP){
                            calculation.referralFees = (calculation.taxable * item.referralFeesPercent) / 100;
                            calculation.bexCommission = (calculation.taxable * item.bexCommissionPercent) / 100;                        
                            calculation.coinsValue = Math.round((calculation.mopAmount * item.bexCoinsPercent) / 100);
                            calculation.coins = calculation.coinsValue * coinSettings.data.settings.multiplier;
                            calculation.partnerCommission = calculation.mopAmount - calculation.coinsValue;
                        }
                    });
                    
                    // TODO: find the burn rate of the product
                    /*
                        BURN RATE FORMULA
                    
                        Br = (Logistic Cost / (SellingPrice - ManufacturingCost - MOP)) * 100
                    */                                       
                    calculation.zonePricing = zonePricing                    
                    
                    this.printBreakUp(calculation);
                }catch(err){
                    let str = `<center>
                        <h2>Not Servicable</h2>
                        <h4>${ err.response.data.err }</h4>
                    </center>`
                    this.loader = false
                    document.getElementById('calulations-section').innerHTML = str;
                }                
            }                        
        },
        getCheapestPrice(pricing, v){
            return new Promise((resolve) => {
                let cheapest = 100000;
                let cheapestCourier = null;                        
                pricing.rate_list.map((val) => {                                                
                    if(val.delivered_charges < cheapest){                                        
                        cheapest = val.delivered_charges                                        
                        cheapestCourier = val
                    }
                })
                cheapestCourier.shippingZone = v.zone;
                cheapestCourier.zoneDesc = v.desc;
                resolve(cheapestCourier);
            })            
        },
        fetchSettings(key){
            return new Promise((resolve) => {
                let request = {
                    method: 'GET',
                    url: 'https://api.bexcart.com/api/settings?key='+key,
                    headers: {}
                };
                axios(request)
                    .then((response) => {                        
                        resolve(response.data);
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });            
            });                  
        },

        // TODO: fetch pricing
        fetchPickrrPricing(from, to){
            return new Promise((resolve, reject) => {
                let request = {
                    method: 'GET',
                    url: 'https://pickrr.com/api-v2/client/fetch-price-calculator-generic/',
                    params: {
                        auth_token: process.env.VUE_APP_PICKRR_TOKEN,
                        shipment_type: 'forward',
                        pickup_pincode: from,
                        drop_pincode: to,
                        delivery_mode: 'express',
                        length: this.length,
                        breadth: this.breadth,
                        height: this.height,
                        weight: this.weight,
                        payment_mode: this.paymentMode                    
                    },
                    headers: {}
                }

                if(this.paymentMode == 'cod')
                    request.params.cod_amount = this.offer_price

                axios(request)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })            
        },
             
        printBreakUp(data){
            let str = `<h4 class="text-center mt-4">DETAILED BREAKUP</h4>            
            <div class="row mt-4 justify-content-md-center">                                
                <div class="col-md-5">
                    <div class="card">
                        <div class="card-body text-center">
                            <h1>
                                <img src="${ this.bexCoinImg }" width="45px" />
                                ${ data.coins } Coins
                            </h1>
                        </div>    
                    </div>                    
                </div>                
            </div>
            <table class="table table-striped table-lg mt-5">
                <tbody>
                    <tr>
                        <td>Production Cost</td><td class="text-right">Rs. ${data.mfg.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>MRP</td><td class="text-right">Rs. ${data.mrp.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>% Off (${data.offPercent.toFixed(2)})</td><td class="text-right"><span class="text-danger">(-)</span> Rs. ${data.off.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>Offer Price</td><td class="text-right">Rs. ${data.sp.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>GST(${ this.gst }%)</td><td class="text-right"><span class="text-danger">(-)</span> Rs. ${( data.sp - data.taxable ).toFixed(2)}</td>
                    </tr>
                    <tr class="bg-grey">
                        <th>Taxable</th><th class="text-right">Rs. ${data.taxable.toFixed(2)}</th>
                    </tr>                    
                    <tr>
                        <th>Margin on Product (MOP)<small>(${this.mop}%)</small></th><th class="text-right"><span class="text-danger">(-)</span> Rs. ${data.mopAmount.toFixed(2)}</th>
                    </tr>
                    <tr>
                        <td class="text-right"><small>BEX Coins Value</small></td><td class="text-right"><small>Rs. ${ data.coinsValue.toFixed(2) }</small></td>
                    </tr>
                    <tr>
                        <td class="text-right"><small>BEX Partner Commission</small></td><td class="text-right"><small>Rs. ${ data.partnerCommission.toFixed(2) }</small></td>
                    </tr>                                                
                </tbody>
                <tfoot>
                    <tr class="bg-grey">
                        <th>Sub Total</th><th class="text-right">Rs. ${ (data.taxable - data.mopAmount).toFixed(2) }</th>
                    </tr>
                </tfoot>
            </table>`;
            let totalDeductions = parseFloat(data.referralFees + data.bexCommission);
            str += `<h5 class="text-center">DEDUCTIONS</h5>            
            <table class="table table-striped table-lg">
                <tbody>
                    <tr>
                        <th>BEX Fees</th><th class="text-right"><span class="text-danger">(-)</span> Rs. ${ parseFloat(data.referralFees + data.bexCommission).toFixed(2) }</th>
                    </tr>
                    <tr>
                        <td class="text-right"><small>Referral Fees</small></td><td class="text-right"><small>Rs. ${data.referralFees.toFixed(2)}</small></td>
                    </tr>
                    <tr>
                        <td class="text-right"><small>BEX Commission</small></td><td class="text-right"><small>Rs. ${data.bexCommission.toFixed(2)}</small></td>
                    </tr>                    
                </tbody>                
            </table>`;

            str += `<div class="row">
                        <div class="col-md-12 bg-grey">
                            <div class="row mb-5">
                                <div class="col-md-12 mt-4 mb-4">
                                    <h5 class="text-center">STANDARD DELIVERY CHARGES AS PER ZONES</h5>
                                    <p class="text-center"><small>Below given delivery charges are as per the current standards and are subject to change.</small></p>
                                    <div class="row justify-content-md-center">
                                        <div class="col-md-2">
                                            <p>Burn Rate Indicators: </p>
                                        </div>
                                        <div class="col-md-7">                                            
                                            <div class="indicator-container">
                                                <div class="indicator profitable">
                                                </div>
                                                <span>Profit</span>
                                            </div>
                                            <div class="indicator-container">
                                                <div class="indicator average-profitable">
                                                </div>
                                                <span>Good</span>
                                            </div>
                                            <div class="indicator-container">
                                                <div class="indicator less-profitable">
                                                </div>
                                                <span>Average</span>
                                            </div>
                                            <div class="indicator-container">
                                                <div class="indicator loss">
                                                </div>
                                                <span>Bad</span>
                                            </div>
                                            <div class="indicator-container">
                                                <div class="indicator negative">
                                                </div>
                                                <span>Very Bad</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>`                
            let zonePricing = data.zonePricing
            let settlementRows = ''
            for(let i= 0; i < zonePricing.length; i++){                                                 
                let burnRate = (parseFloat(zonePricing[i].delivered_charges) / (data.taxable - (data.mfg + data.referralFees + data.bexCommission + data.coinsValue + data.mopAmount))) * 100
                let burnRateClass = null
                if(burnRate >= 0 && burnRate <= 20)
                    burnRateClass = 'profitable'
                else if(burnRate > 20 && burnRate <= 28)
                    burnRateClass = 'average-profitable'
                else if(burnRate > 28 && burnRate <= 35)
                    burnRateClass = 'less-profitable'
                else if(burnRate > 35 && burnRate <= 50)
                    burnRateClass = 'loss'
                else
                    burnRateClass = 'negative'
                str += `<div class="col">
                            <div class="card">
                                <div class="card-body text-center" @click.prevent="test()">
                                    <h6 class="card-title mb-0">ZONE ${ zonePricing[i].shippingZone }</h6>
                                    <small class="mb-2">${ zonePricing[i].zoneDesc }</small>
                                    <h4>Rs. ${ zonePricing[i].delivered_charges }</h4>
                                </div>
                                <div class="card-footer text-center ${ burnRateClass }">
                                    <small>BURN RATE(%)</small>
                                    <h4>${ Math.round(burnRate) }%</h4>
                                </div>
                            </div>
                        </div>`                
                let gstOnFees = ((totalDeductions + zonePricing[i].delivered_charges) * 18)/ 100
                let finalSettlement = data.taxable - (data.mopAmount + data.referralFees + data.bexCommission + data.coinsValue + zonePricing[i].delivered_charges + gstOnFees)
                settlementRows += `<td class="text-center">
                    <small>GST on Fees(%)</small><h5>Rs. ${ gstOnFees.toFixed(2) }</h5>
                    <small>Total</small><h5>Rs. ${ finalSettlement.toFixed(2) }</h5>
                </td>`
            }            
            str += `</div>
            </div></div>`
            
            // let gstOnFees = ((totalDeductions + averageDelivery) * 18) / 100

            str += `<table class="table table-striped table-lg">
                <thead>
                    <tr class="text-center">
                        <th>ZONE A<br><small>Final Settlement</small></th><th>ZONE B<br><small>Final Settlement</small></th><th>ZONE C<br><small>Final Settlement</small></th><th>ZONE D<br><small>Final Settlement</small></th><th>ZONE E<br><small>Final Settlement</small></th>
                    </tr>
                </thead>
                <tbody>                    
                    <tr class="bg-titan">
                    ${ settlementRows } 
                    </tr>
                    </tbody>
            </table>`
            this.loader = false;
            document.getElementById('calulations-section').innerHTML = str;            
        }
    }
}
</script>

<style scoped>
</style>