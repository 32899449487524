<template>
    <div class="text-justify">
        <p>Product from the Manufacturer/ Producer/ Brand Owners is sold directly to customers.Thus reducing the distance between premium brands and its customers. We appoint sales agents/partners who act as brand ambassadors for the brands where they actively promote the products onboarded on BEX  and make sales.</p>
        <p>Our BEXPartners can make their career by joining BEX wherein they earn commission on every sale made by them and their invited subscriber or BEXPartner. The company focuses on mentoring our BEXPartners by training them with various skillsets such as communications, marketing, sales, and reselling so that they can market your Brands more effectively.</p>
        <p>Every subscriber enters BEX with the invitation code of BEXPartner which makes us build a community of vendors, resellers and buyers who are interlinked with each other.</p>
        <p>Major en customers around the world buy products with limited knowledge of those products where they end up buying the wrong product at a higher price. Here at BEX, our BEXPartner educates the buyers by providing the right information about the products and promoting them to buy the right product at an affordable cost.</p>
        <p>BEX has a unique feature called quantity discount which means the greater the quantity the less the price. It has also come up with an amazing feature called group buying, wherein users can create a group or join an existing group and make a purchase of a product in a group at a discounted price.</p>
        <p>BEX aims to reach every home and replace their daily use regular products with premium products. We aim to change the lifestyle of every individual.</p>
        <p>We at BEX want to grow by growing our all-associated partners, vendors, and subscribers.</p>
    </div>
</template>

<script>
export default {
    name: 'WhyBEX'
}
</script>