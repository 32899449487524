<template>
    <section>
        <b-container>
            <div class="section-title">
                <h2>{{ title }}</h2>
                <p><small>{{ smallText }}</small></p>
            </div>            
            <component v-bind:is="componentName"/>            
        </b-container>
    </section>
</template>

<script>
import HowWorksContent from '../components/HowWorksContent.vue'
import WhatBEX from '../components/WhatBEX.vue'
import Calculator from '../components/content/Calculator.vue'
import Signup from '../components/Signup.vue'
import KeyTerms from '../components/content/KeyTerms.vue'
import WhyBEX from '../components/WhyBEX.vue'

export default {
    name: 'Section',    
    props:['title', 'smallText', 'componentName'],
    components: {
        HowWorksContent,
        WhatBEX,
        WhyBEX,
        Signup,
        Calculator,
        KeyTerms
    }
}
</script>
