<template>
    <div>
        <b-row class="border-dashed-left border-dashed-bottom alt pt-3">
            <b-col md="7" class="text-justify">
                <h3><span class="label label-primary">1</span> Registration in less than 5min</h3>
                    <p>Resgister your brand on BEX <br>To register you only need following details</p>
                    <ul>
                        <li>GSTIN</li>
                        <li>Corporate Bank Account</li>
                        <li>Aadhaar Card</li>
                        <li>PAN Card</li>
                    </ul>
                    <p>Once you complete your registration, you will have access to BEX Vendor Panel.</p>                    
            </b-col>
            <b-col md="5">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/HcYuZkq-e4o"></iframe>
                </div>
            </b-col>
        </b-row>
        <b-row class="border-dashed-right border-dashed-bottom pb-2">
            <b-col md="5" class="pt-3">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/HcYuZkq-e4o"></iframe>
                </div>
            </b-col>
            <b-col md="7" class="pt-3 text-justify">
                <h3><span class="label label-primary">2</span> Add your Brand &amp; Products</h3>
                <p>Once Registered on BEX Vendor Panel, you can easily add your products, manage inventory, process orders and check your payment status after selling on BEX.</p>
                <p>You have to raise a request to add your brand in the panel listing so you can select it while uploading your product.</p>
            </b-col>            
        </b-row>
        <b-row class="border-dashed-left border-dashed-bottom alt">            
            <b-col md="7" class="pt-3 text-justify">
                <h3><span class="label label-primary">3</span> Assign your Product's Selling Price</h3>
                <p>When your products are uploaded on BEX App, more than 2000+ active Bex Partners will be able to view your products along with thousands of end-users on the BEX Customer Website.</p>
                <p>Make sure to increase momentum, this can be done by setting the right price for the uploaded product.</p>
                <p>You can use the price calculator to fix what Margin On Product (MOP) will work best for you, keeping the Factory Product Price in mind while setting the offer price.</p>
                <p>Note: Making available the product at a high MOP will increase sales as BEX partners (Sales associates for your product) will feel more motivated to market your product more.</p>
            </b-col>         
            <b-col md="5" class="pt-3">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/HcYuZkq-e4o"></iframe>
                </div>
            </b-col>   
        </b-row>
        <b-row class="border-dashed-right border-dashed-bottom">            
            <b-col md="5" class="pt-3">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/HcYuZkq-e4o"></iframe>
                </div>
            </b-col>
            <b-col md="7" class="pt-3 text-justify">
                <h3><span class="label label-primary">4</span> Delivery</h3>
                <p>When a customer places an order for your listed product then BEX notifies you by Email, SMS, as well as on the BEX Vendor Panel.</p>
                <p>We have tied up with multiple Logistics Partners who pick the products from your location and deliver it straight to the customer.</p>
                <p>After receiving an order, please go to the Vendor Panel and do the following steps:
                    <ul>
                        <li>Accept your order</li>
                        <li>Download label and manifest</li>
                        <li>Pack the product and paste the label on the packaging</li>
                        <li>Hand over the product to our logistics partners.</li>
                    </ul>
                </p>
            </b-col>               
        </b-row>
        <b-row class="border-dashed-left alt">            
            <b-col md="7" class="pt-3 text-justify"> 
                <h3><span class="label label-primary">5</span> Payment</h3>
                <p>Payment for your sales is deposited securely into your bank account bi-monthly.</p>
                <p>When an order is delivered the amount is primarily reciprocated in your account after 72 hours from order delivery completion including cash on Delivery Orders. However, a complete settlement happens on the 15th and 30th day of every month after deducting logistics costs and other applicable costs.</p>
                <p>Example: If your product is successfully delivered on 1st January 2021, money for that product will be reciprocated in your BEX account after 72hrs,i.e. on 4th January 2021. And the total settlement of that amount will happen on the 15th of Jan.</p>
                <p>Note: You will have complete control over whether you want to process orders based on Logistic cost. If you feel the logistics costs are not going to work out for you then you can choose to not process that product to that mentioned location. There will be complete transparency on Costs pertaining to logistics as logistics costs will be bared by Vendors.</p>
                <p>You can view your deposited balance along with future payments and other things in your BEX Panel.</p>
            </b-col>               
            <b-col md="5" class="pt-3">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/HcYuZkq-e4o"></iframe>
                </div>
            </b-col>            
        </b-row>        
    </div>
</template>

<script>
export default {
    name: 'HowWorksContent',
    components: {
      
    }
}
</script>