<template>
    <div class="text-justify">
        <p>BEX is a Web and App-based Reseller Platform which only deals with guaranteed premium Daily Essential Products.</p>
        <p>BEX App social commerce will act as a tool for BEX PARTNERS who are a Large Network of paid professionals who will act as ambassadors to your products and strive to acquire the trust of less-informed customers through interpersonal knowledge sharing.</p>
        <p>A checklist is provided to the vendor to make sure of the various quality parameters which include its ingredients, packaging, usage, etc… Any products that have passed these parameters are onboard to our portal. We also ensure any new brand with premium quality products reaches its well-deserved success.</p>
    </div>
</template>

<script>
export default {
    name: 'WhatBEX'
}
</script>