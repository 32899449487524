<template>
    <div>
        <b-row class="mt-2">
            <b-col md="4" class="mt-2">                
                <div class="card">
                    <div class="card-body" style="min-height: 100px;">
                        <h6 class="card-title">                            
                            Offer Price
                        </h6>
                        <div class="pl-3 pr-3 text-justify">
                            <p>Price at which you want to sell your product to the customers.</p>
                        </div>
                    </div>
                </div>
            </b-col>                        
            <b-col md="4" class="mt-2">
                <div class="card">
                    <div class="card-body" style="min-height: 100px;">
                        <h6 class="card-title">                            
                            Delivery Fees
                        </h6>
                        <div class="pl-3 pr-3 text-justify">
                            <p>This is the logistic charges for delivering the products.</p>                        
                        </div>                        
                    </div>
                </div>
            </b-col>            
            <b-col md="4" class="mt-2">
                <div class="card">
                    <div class="card-body" style="min-height: 100px;">
                        <h6 class="card-title">                            
                            Final Settlement
                        </h6>        
                        <div class="pl-3 pr-3 text-justify">
                            <p>Final Settlement Amount that will be deposited in Vendor Bank Account after applicable deductions.</p>                        
                        </div>                                        
                    </div>
                </div>
            </b-col>
        </b-row>        

        <b-row class="mt-2">    
            <b-col md="4" class="mt-2">
                <div class="card">
                    <div class="card-body" style="min-height: 150px;">
                        <h6 class="card-title">                            
                            Margin on Product (MOP)
                        </h6> 
                        <div class="pl-3 pr-3 text-justify">
                            <p>This is the amount that you as a vendor have agreed to pay BEX Partners (Sales Assoicates) for marketing your product.</p>                        
                        </div>                                               
                    </div>
                </div>
            </b-col>                                
            <b-col md="4" class="mt-2">
                <div class="card">
                    <div class="card-body" style="min-height: 150px;">
                        <h6 class="card-title">                            
                            Closing Fees
                        </h6>
                        <div class="pl-3 pr-3 text-justify">
                            <p>This is the banking charges applied for online payments.<br>
                            This amount differs based on the mode of payment. Usually between 0.5% to 3% of transaction amount.</p>                        
                        </div>                                                                        
                    </div>
                </div>
            </b-col>
            <b-col md="4" class="mt-2">
                <div class="card">
                    <div class="card-body" style="min-height: 150px;">
                        <h6 class="card-title">                            
                            GST on Fees
                        </h6>        
                        <div class="pl-3 pr-3 text-justify">
                            <p>This is the tax applied on the charges charged by BEX. Following are the fees on which GST of 18% is calculated.<br>
                            Delivery Charges, BEX Fees, BEX Coins Value and Closing Fees.</p>                        
                        </div>                                        
                    </div>
                </div>
            </b-col>
        </b-row>               
        <b-row class="mt-2">
            <b-col md="6" class="mt-2">
                <div class="card">
                    <div class="card-body" style="min-height: 165px;">
                        <h6 class="card-title">                            
                            Burn Rate
                        </h6>   
                        <div class="pl-3 pr-3 text-justify">
                            <p><b>Burn Rate</b> is the rate of amount which is calculated on logistic cost over final settlement amount. This helps in understand the rate of loss over logistic.</p>
                            <p><b>Note: </b>Vendors can affix burn rate of their preference as per their brand Goals. To get precise burn rate it is recommended to set the Production cost of the product.</p>
                            <!-- <b-progress class="mt-2" show-value height="2rem">
                                <b-progress-bar :value="20" variant="profit">Profit</b-progress-bar>
                                <b-progress-bar :value="20" variant="average-profit">Good</b-progress-bar>
                                <b-progress-bar :value="20" variant="less-profit">Average</b-progress-bar>
                                <b-progress-bar :value="20" variant="loss">Bad</b-progress-bar>
                                <b-progress-bar :value="20" variant="negative">Very Bad</b-progress-bar>
                            </b-progress> -->
                        </div>                                                                     
                    </div>
                </div>
            </b-col>
            <b-col md="6" class="mt-2">
                <div class="card">
                    <div class="card-body" style="min-height: 165px;">
                        <h6 class="card-title">                            
                            BEX Coins & BEX Coins Value
                        </h6>
                        <div class="pl-3 pr-3 text-justify">
                            <p>BEX Coins a type of system that rewards the customers for every purchase they make on BEX. Customers can then use these BEX coins and redeem them to buy applicable BEX Promise products.<br>                            BEX Coins Value is the value of coins in rupees.</p>                        
                        </div>                                                
                    </div>
                </div>
            </b-col>            
        </b-row>        
    </div>
</template>

<script>
export default {
    name: 'KeyTerms'
}
</script>

<style scoped>
.card{
    border-radius: 10px;
    overflow: hidden;
    border: none;
}
.card .card-body{
    padding: 0;   
    background: #EFEDFF; 
    font-size: 14px;    
}

.card .card-title{ 
    background: #8A62A7;
    color: #fff;
    text-align: center;
    /* display: inline-block;   
    border-bottom: 2px solid #8A62A7; */
    padding: 8px 0px 8px 0px;
}
</style>